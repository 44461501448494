<template>
  <div class="wrapper d-flex flex-column flex-grow-1">
    <!-- 
      Section principale
     -->
    <div class="d-flex flex-row flex-grow-1 align-items-stretch">
      <!-- Éditeur -->
      <div class="editor d-flex flex-column flex-grow-1">
        <composer
          v-if="documentTemplate"
          :editor="editor"
          :template="documentTemplate.template"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@axios";

import { Editor } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";

import Composer from "@/components/editor/Composer";

import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

import DynamicField from "@/components/editor/plugins/dynamic-field/DynamicFieldExtension.js";
import Checkboxes from "@/components/editor/plugins/checkboxes/CheckboxesExtension.js";
import Questionnaire from "@/components/editor/plugins/questionnaire/QuestionnaireExtension.js";
import CustomImage from "@/components/editor/plugins/custom-image/CustomImageExtension.js";
import Header from "@/components/editor/plugins/header/HeaderExtension.js";
import Footer from "@/components/editor/plugins/footer/FooterExtension.js";
import FontSize from "@/components/editor/plugins/FontSize.js";
import LineHeight from "@/components/editor/plugins/LineHeight.js";

import Formula from "fparser";
import slugify from "slugify";

export default {
  components: {
    Composer,
  },
  data() {
    return {
      editor: null,
      documentTemplate: null,
      signatureRequest: null,
    };
  },
  mounted() {
    const headers = {
      headers: { Authorization: `Bearer ${this.$route.query.jwt}` },
    };

    axios
      .get(`/signature-requests/${this.$route.query.signatureRequest}`, headers)
      .then(({ data }) => {
        this.signatureRequest = data;
        this.documentTemplate = this.signatureRequest.documentModel;

        this.editor = new Editor({
          extensions: [
            StarterKit,
            Underline,
            DynamicField,
            Checkboxes,
            Questionnaire,
            Text,
            TextStyle,
            FontFamily,
            Color,
            CustomImage.configure({
              HTMLAttributes: {
                class: "custom-image",
              },
            }),
            TextAlign.configure({
              types: ["heading", "paragraph"],
              defaultAlignment: "left",
            }),
            Table,
            TableRow,
            TableHeader,
            TableCell,
            Checkboxes,
            Questionnaire,
            FontSize,
            LineHeight,
            Header,
            Footer,
          ],
          editable: false,
          content: this.signatureRequest.fullContent,
          editorProps: {
            attributes: {
              class: "mx-auto",
            },
          },
          autofocus: true,
          injectCSS: false,
          onCreate: ({ editor }) => {
            this.updateFormulas(editor);
            console.log(document.querySelector(".composer-wrapper").innerHTML);
            axios
              .post(
                `/signature-requests/${this.signatureRequest.hash}/sign`,
                {
                  documentHtml:
                    document.querySelector(".composer-wrapper").innerHTML, // Envoyer le HTML "réel"
                  documentJson: editor.getJSON(), // Nécessaire pour mettre à jour les données du signataire dans l'annuaire
                },
                headers
              )
              .then(() => {
                this.done();
              })
              .catch(() => {
                this.done();
              });
          },
        });
      })
      .catch(() => {
        this.done();
      });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    slugify(str) {
      return slugify(str, {
        replacement: "_",
        remove: /[*+~.()'"!:@]/g,
        lower: true,
      });
    },
    /**
     * Update all formulas within the document
     */
    updateFormulas(editor) {
      // Find formulas within document
      const formulas = [];
      (function findFormulas(obj) {
        if (!obj.hasOwnProperty("children")) return;

        for (let i = 0; i < obj.children.length; i++) {
          findFormulas(obj.children[i]);
          if (obj.children[i]?.spec?.node?.attrs?.type === "formula") {
            formulas.push(obj.children[i]);
          }
        }
      })(editor.view.docView);

      const thsSlugify = this.slugify; // Preserve scope for later usage

      // Process each formula
      for (const formula of formulas) {
        // Fetch fields from formula
        const fieldsArray = [];
        formula.spec.node.attrs.formula =
          formula.spec.node.attrs.formula.replace(
            /\[([^)]+?)\]/g,
            (match, group1) => {
              const variable = thsSlugify(group1);
              fieldsArray.push(variable);
              return `[${variable}]`;
            }
          );

        // Convert fields to object  ({ "A1": null, ... })
        const fields = fieldsArray.reduce(
          (obj, field) => ((obj[field.replace(/[\[\]]/g, "")] = null), obj),
          {}
        );

        // Find variables values within document (i.e. populate fields object)
        (function findFields(obj, fields) {
          if (!obj.hasOwnProperty("children")) return;

          for (let i = 0; i < obj.children.length; i++) {
            findFields(obj.children[i], fields);
            if (
              obj.children[i]?.spec?.node?.attrs?.type &&
              fields.hasOwnProperty(
                thsSlugify(obj.children[i].spec.node.attrs.label)
              ) &&
              fields[thsSlugify(obj.children[i].spec.node.attrs.label)] === null
            ) {
              fields[thsSlugify(obj.children[i].spec.node.attrs.label)] =
                obj.children[i].spec.node.attrs.content;
            }
          }
        })(editor.view.docView, fields);

        // Parse and evaluate (i.e. compute) formula
        const fObj = new Formula(formula.spec.node.attrs.formula);
        formula.spec.node.attrs.content = fObj.evaluate(fields);
      }
    },
    done() {
      const doneDiv = document.createElement("div");
      doneDiv.id = "request-sent";
      document.body.appendChild(doneDiv);
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  overflow: hidden;
}

.action-btn {
  position: fixed;
  bottom: 30px;
  right: 60px;
}
</style>
