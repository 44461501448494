<template>
  <div class="wrapper d-flex flex-column flex-grow-1">
    <!-- 
      Section principale
     -->
    <div class="d-flex flex-row flex-grow-1 align-items-stretch">
      <!-- Éditeur -->
      <div class="editor d-flex flex-column flex-grow-1">
        <composer
          class="responsive-composer"
          :editor="editor"
          :template="documentTemplate.template"
          v-if="documentTemplate"
        />
      </div>
    </div>

    <div class="action-btn">
      <b-button v-if="actionLoading" variant="primary" disabled>
        <b-spinner small class="mr-50" /> Chargement...
      </b-button>
      <b-button v-else-if="actionBtnDisabled" variant="primary" disabled>
        Champs obligatoires à remplir (*)...
      </b-button>
      <button
        v-else
        type="button"
        class="btn btn-primary"
        @click.prevent="send()"
      >
        <i :class="`ri-${actionBtnIcon} mr-50`" />
        {{ actionBtnText }}
      </button>
    </div>
  </div>
</template>

<script>
import axios from "@axios";

import { Editor } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Text from "@tiptap/extension-text";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import FontFamily from "@tiptap/extension-font-family";

import Composer from "@/components/editor/Composer";

import Table from "@/components/editor/plugins/table/CustomTableExtension.js";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";

import DynamicField from "@/components/editor/plugins/dynamic-field/DynamicFieldExtension.js";
import Checkboxes from "@/components/editor/plugins/checkboxes/CheckboxesExtension.js";
import Questionnaire from "@/components/editor/plugins/questionnaire/QuestionnaireExtension.js";
import CustomImage from "@/components/editor/plugins/custom-image/CustomImageExtension.js";
import Header from "@/components/editor/plugins/header/HeaderExtension.js";
import Footer from "@/components/editor/plugins/footer/FooterExtension.js";
import FontSize from "@/components/editor/plugins/FontSize.js";
import LineHeight from "@/components/editor/plugins/LineHeight.js";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Formula from "fparser";
import slugify from "slugify";
import dayjs from "dayjs";

export default {
  components: {
    Composer,
  },
  data() {
    return {
      editor: null,
      documentTemplate: null,
      signatureRequest: null,
      actionLoading: false,
      genericSignature: null,
      sendToVitalSign: null,
      actionBtnIcon: "send-plane-line",
      actionBtnText: "Envoyer pour signature",
      actionBtnDisabled: true,
      refreshInterval: null,
    };
  },
  mounted() {
    this.genericSignature = this.$route.query.generic == 1;
    this.sendToVitalSign = !this.genericSignature || this.$route.query.noSign == 0;

    if (this.genericSignature) {
      this.fetchDocumentTemplate();
      if (!this.sendToVitalSign) {
        this.actionBtnIcon = "check-line";
        this.actionBtnText = "Finaliser ma saisie";
      }
    } else {
      this.fetchSignatureRequest();
    }

    this.refreshInterval = setInterval(() => {
      if (!this.editor) return;
      const missingFields = this.checkRequiredFields(this.editor);
      console.log(missingFields);
      this.actionBtnDisabled = Boolean(missingFields.length);
    }, 2000);
  },
  beforeDestroy() {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    this.editor.destroy();
  },
  methods: {
    /**
     * Fetch signature request (containing pre-filled document JSON)
     */
    fetchSignatureRequest() {
      axios
        .get(
          `/signature-requests/${this.$route.params.id}/hash`,
          { params: { firstRecipient: this.$route.query.firstRecipient } }
        )
        .then(({ data }) => {
          this.signatureRequest = data;
          this.documentTemplate = this.signatureRequest.documentModel;
          this.loadEditor();
        })
        .catch((err) => {
          console.log(err);
          alert(err.toString());
        });
    },
    /**
     * Fetch signature request (containing pre-filled document JSON)
     */
    fetchDocumentTemplate() {
      axios
        .get(`/document-templates/${this.$route.params.id}/hash`)
        .then(({ data }) => {
          this.documentTemplate = data;
          this.loadEditor();
        })
        .catch((err) => {
          console.log(err);
          alert(err.toString());
        });
    },
    /**
     * Load the editor according to content in this.documentTemplate
     */
    loadEditor() {
      this.editor = new Editor({
        extensions: [
          StarterKit,
          Underline,
          DynamicField,
          // Checkboxes,
          // Questionnaire,
          // Text,
          TextStyle,
          FontFamily,
          Color,
          CustomImage.configure({
            HTMLAttributes: {
              class: "custom-image",
            },
          }),
          TextAlign.configure({
            types: ["heading", "paragraph"],
            defaultAlignment: "left",
          }),
          Table,
          TableRow,
          TableHeader,
          TableCell,
          Checkboxes,
          Questionnaire,
          FontSize,
          LineHeight,
          Header,
          Footer,
        ],
        editable: false,
        content: this.documentTemplate.content,
        editorProps: {
          attributes: {
            class: "mx-auto",
          },
        },
        autofocus: true,
        injectCSS: false,
        // onSelectionUpdate: ({ editor }) => {
        // The selection has changed.
        onTransaction: ({ editor }) => {
          this.updateFormulas(editor);
        },
      });
    },
    slugify(str) {
      return slugify(str, {
        replacement: "_",
        remove: /[*+~.()'"!:@]/g,
        lower: true,
      });
    },
    /**
     * Check if required fields are missing within the document
     * Returns an array of labels which are required but empty
     * Returns an empty array if no fields are missing
     */
    checkRequiredFields(editor) {
      const missingFields = new Set();
      // console.log(editor.view.docView);
      (function findMissingFields(obj, missingFields) {
        if (obj.hasOwnProperty("children")) {
          for (let i = 0; i < obj.children.length; i++) {
            findMissingFields(obj.children[i], missingFields);
            if (
              obj.children[i].spec &&
              obj.children[i].spec.node &&
              obj.children[i].spec.node.attrs &&
              obj.children[i].spec.node.attrs.label &&
              obj.children[i].spec.node.attrs.required &&
              !obj.children[i].spec.node.attrs.formula &&
              (
                ( // If there is no content (e.g. dynamic field)
                  'content' in obj.children[i].spec.node.attrs &&
                  !obj.children[i].spec.node.attrs.content
                ) ||
                ( // If no checkbox/radio button is selected
                  obj.children[i].spec.node.attrs.checkboxes &&
                  !obj.children[i].spec.node.attrs.checkboxes.find(e => e.selected)
                )
              )
            ) {
              // console.log(obj.children[i].spec.node.attrs.label, obj.children[i].spec.node.attrs);
              missingFields.add(obj.children[i].spec.node.attrs.label);
            }
          }
        }
      })(editor.view.docView, missingFields);

      return [...missingFields];
    },
    /**
     * Update all formulas within the document
     */
    updateFormulas(editor) {
      // Find formulas within document
      const formulas = [];
      (function findFormulas(obj) {
        if (!obj.hasOwnProperty("children")) return;

        for (let i = 0; i < obj.children.length; i++) {
          findFormulas(obj.children[i]);
          if (obj.children[i]?.spec?.node?.attrs?.type === "formula") {
            formulas.push(obj.children[i]);
          }
        }
      })(editor.view.docView);

      const thsSlugify = this.slugify; // Preserve scope for later usage

      // Process each formula
      for (const formula of formulas) {
        // if formula on numbers
        if (formula.spec.node.attrs.formulatype === "number") {
          // Fetch fields from formula
          const fieldsArray = [];
          formula.spec.node.attrs.formula =
            formula.spec.node.attrs.formula.replace(
              /\[([^)]+?)\]/g,
              (match, group1) => {
                const variable = thsSlugify(group1);
                fieldsArray.push(variable);
                return `[${variable}]`;
              }
            );

          // Convert fields to object  ({ "A1": null, ... })
          const fields = fieldsArray.reduce(
            (obj, field) => ((obj[field.replace(/[\[\]]/g, "")] = null), obj),
            {}
          );

          // Find variables values within document (i.e. populate fields object)
          (function findFields(obj, fields) {
            if (!obj.hasOwnProperty("children")) return;

            for (let i = 0; i < obj.children.length; i++) {
              findFields(obj.children[i], fields);
              if (
                obj.children[i]?.spec?.node?.attrs?.type &&
                fields.hasOwnProperty(
                  thsSlugify(obj.children[i].spec.node.attrs.label)
                ) &&
                fields[thsSlugify(obj.children[i].spec.node.attrs.label)] ===
                  null
              ) {
                fields[thsSlugify(obj.children[i].spec.node.attrs.label)] =
                  obj.children[i].spec.node.attrs.content;
              }
            }
          })(editor.view.docView, fields);

          function toFixedIfNecessary(value, dp) {
            return +parseFloat(value).toFixed(dp);
          }

          // Parse and evaluate (i.e. compute) formula
          const fObj = new Formula(formula.spec.node.attrs.formula);
          formula.spec.node.attrs.content = toFixedIfNecessary(fObj.evaluate(fields), 2);

          // if formula on dates
        } else if (formula.spec.node.attrs.formulatype === "date") {
          let date = "";

          (function findDate(obj) {
            if (!obj.hasOwnProperty("children")) return;

            for (let i = 0; i < obj.children.length; i++) {
              findDate(obj.children[i]);
              if (
                obj.children[i]?.spec?.node?.attrs?.format === "date" &&
                obj.children[i]?.spec?.node?.attrs?.label ===
                  formula.spec.node.attrs.formuladate &&
                obj.children[i]?.spec?.node?.attrs?.content.length
              ) {
                console.log(obj.children[i].spec.node.attrs.content);
                date = obj.children[i].spec.node.attrs.content;
              }
            }
          })(editor.view.docView);

          if (date === "Date du jour") {
            date = dayjs()
              .add(formula.spec.node.attrs.daystoadd, "day")
              .format("DD/MM/YYYY");
          } else if (date.length) {
            date = dayjs(date)
              .add(formula.spec.node.attrs.daystoadd, "day")
              .format("DD/MM/YYYY");
          } else {
            date = "[Date en attente de renseignement]"
          }

          formula.spec.node.attrs.content = date;
        }
      }
    },
    /**
     * Send the document to API to complete the signature process
     */
    send() {
      this.updateFormulas(this.editor);

      const missingFields = this.checkRequiredFields(this.editor);
      if (missingFields.length) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: `Champs obligatoires : ${missingFields.join(", ")}`,
              icon: "XIcon",
              variant: "danger",
            },
          },
          { timeout: 3500 }
        );

        return;
      }

      this.actionLoading = true;
      const endpoint =
        this.$route.query.generic == "1"
          ? `/document-templates/${
              this.documentTemplate.hash
            }/sign?sendToVitalSign=${Number(this.sendToVitalSign)}`
          : `/signature-requests/${this.signatureRequest.hash}/sign`;

      const documentHtml =
        document.querySelector(".composer-wrapper").innerHTML;
      axios
        .post(
          endpoint,
          {
            // Envoyer le HTML "réel"
            documentHtml,
            // Nécessaire pour mettre à jour les données du signataire dans l'annuaire
            documentJson: this.editor.getJSON(),
          },
          { params: { firstRecipient: this.$route.query.firstRecipient } }
        )
        .then(({ data }) => {
          console.log(data);
          if (this.sendToVitalSign) {
            console.log("=== res vs ===");
            console.log(data["hydra:member"]);
            const signatureLink = data["hydra:member"][0].signPosition
              ? data["hydra:member"].find(e => e.signPosition == 1).link
              : data["hydra:member"][0].link;
            console.log("signatureLink -> ", signatureLink);
            window.location.href = signatureLink;
          } else {
            this.actionLoading = false;
            this.$swal.fire({
              title: "Merci !",
              text: "Vos saisies ont bien été enregistrées. Vous pouvez dès à présent fermer cette fenêtre.",
              icon: "success",
              iconColor: "#2444EC",
              confirmButtonText: "Ok",
              showCancelButton: false,
              buttonsStyling: false,
              width: "512px",
              customClass: {
                confirmButton: "btn btn-danger mr-50 my-1",
              },
            });
          }
        })
        .catch((err) => {
          this.actionLoading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  overflow: hidden;
}

.action-btn {
  position: fixed;
  bottom: 30px;
  right: 60px;
}

/** Responsive action-button */
@media screen and (max-width: 754px) {
  .action-btn {
    position: fixed;
    bottom: 0 !important;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    padding: 0;

    button {
      width: 100%;
      text-align: center;
      display: block;
      border-radius: 0;
    }
  }
}
</style>
